import React from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Navigation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faFacebook, faInstagram, faBilibili } from '@fortawesome/free-brands-svg-icons';
import { SiXiaohongshu, SiWechat} from 'react-icons/si';


function Navigation() {
    const handleExternalLink = (e) => {
        e.preventDefault();
        window.location.href = "https://linktr.ee/mscms"; // Redirect to external link
    };

    return (
        <Navbar bg="transparent" variant="dark" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand as={NavLink} to="/" className="navbar-home">
                    <img
                        src="/assets/icons/icon-mco.svg"
                        alt="Brand Logo"
                        width="125"
                        height="125"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link 
                            as={NavLink}
                            to="/"
                            end
                            className="navbar-link"
                        >
                            Home
                        </Nav.Link>
                        <Nav.Link 
                            as={NavLink}
                            to="/events" 
                            className="navbar-link"
                        >
                            Events
                        </Nav.Link>
                        <Nav.Link
                            href="#"
                            onClick={handleExternalLink}
                            className="navbar-link"
                        >
                            Contact
                        </Nav.Link>
                    </Nav>
                    <Nav>
                        <a href="https://www.youtube.com/channel/UC57hHM5J83Xr62Zj1XOrarw" target="_blank" rel="noopener noreferrer" className="social-icon" role="button">
                            <FontAwesomeIcon icon={faYoutube} size="lg" />
                        </a>
                        <a href="https://www.facebook.com/mcgillstudentschinesemusicsociety/" target="_blank" rel="noopener noreferrer" className="social-icon" role="button">
                            <FontAwesomeIcon icon={faFacebook} size="lg" />
                        </a>
                        <a href="https://www.instagram.com/mcgillchinesemusic_/" target="_blank" rel="noopener noreferrer" className="social-icon" role="button">
                            <FontAwesomeIcon icon={faInstagram} size="lg" />
                        </a>
                        <a href="https://space.bilibili.com/3493290609478210" target="_blank" rel="noopener noreferrer" className="social-icon" role="button">
                            <FontAwesomeIcon icon={faBilibili} size="lg" />
                        </a>
                        <a href="https://www.xiaohongshu.com/user/profile/66dd1f01000000000d025ee4" target="_blank" rel="noopener noreferrer" className="social-icon" role="button">
                            <SiXiaohongshu size="1.5em" />
                        </a>
                        <a href="https://mp.weixin.qq.com/s/4hxNMyhoZ32R8_-58kzk4Q" target="_blank" rel="noopener noreferrer" className="social-icon" role="button">
                            <SiWechat size="1.5em" />
                        </a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;
