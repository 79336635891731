import React, { useEffect, useState, useCallback } from 'react';
import ArrowCTA from '../components/ArrowCTA';
import './Home.css';
import {THEME_COLORS} from '../constants/Constants';

const Home = () => {
    const [scrollY, setScrollY] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const photos = [
        {
            url: "https://i.imgur.com/524ssWe.jpg",
            alt: "Orchestra Performance 1",
            fallback: "url('/assets/images/events-background.jpg')",
        },
        {
            url: "https://i.imgur.com/bADpOJD.jpg",
            alt: "Orchestra Performance 3",
            fallback: "url('/assets/images/home-background.jpg')",
        },
    ];
    const handleNext = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    }, [photos.length]); // Add photos.length as a dependency
    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex - 1 + photos.length) % photos.length
        );
    };
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 3000); // 5 seconds
        return () => clearInterval(interval); // Cleanup on unmount
    }, [handleNext]);

    useEffect(() => {
        const handleScroll = () => {
        setScrollY(window.scrollY); // Track vertical scroll position
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scale = 1 + scrollY / 1000;
    const opacity = 1 - scrollY / 500;
    const opacityArrow = 1 - scrollY / 400;
    const translateY = scrollY * (-0.8);
    const titleStyle = {
        transform: `translateY(-50%) translateZ(0) translateY(${translateY}px)`,
        opacity: opacity,
        scale: scale,
        transition: 'transform 0.3s, opacity 0.1s, scale 0.1s',
        userSelect: 'none',
    }
    const arrowStyle = {
        opacity: opacityArrow,
        transition: 'opacity 0.7s',
        userSelect: 'none',
    }
    const goToEvents = () => {
        window.location.href = '/events'
    }
    return (
        <div className="page-wrapper">
            {/* Hero Section */}
            <div className="hero">
                <h1 style={titleStyle}>
                    Welcome to the McGill Chinese Orchestra!
                </h1>
                <p style={titleStyle}>
                    Preserve the beauty of Chinese music
                </p>
                <div className='home-button-container' style={{'--btn-bg-color': THEME_COLORS.MCO_RED}}>
                    <a href="#about" className="btn">Learn More</a>
                    <button 
                        className="btn"
                        onClick={() => window.location.href = "https://zeffy.com"}
                    >
                    Tickets
                    </button>
                </div>
                <ArrowCTA style={arrowStyle} />
            </div>
            <div className='homepage-content'>
                <div className="video-wrapper">
                    <iframe 
                        src="https://www.youtube.com/embed/HvcV8Og5G0o" 
                        title="McGill Chinese Orchestra Performance"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='content-video-description'>
                    <h2 className="content-video-description-title">A Night of Cultural Splendor</h2>
                    <h3 className="content-video-description-subtitle">2024 Montreal Chinese Student Spring Festive Gala</h3>
                    <p className="content-video-description-content">
                        On February 8, 2024, the Grand Chinese New Year Gala illuminated Montreal with a 
                        dazzling celebration of the Year of the Dragon. As proud performers, the McGill Chinese 
                        Orchestra brought the stage to life with an unforgettable musical journey, showcasing the 
                        rich and timeless beauty of Chinese traditions.
                    </p>
                    <button 
                        className="btn"
                        onClick={goToEvents}
                        style={{fontFamily:'EB Garamond', '--btn-bg-color': THEME_COLORS.MCO_RED}}
                    >
                        Our Events
                    </button>
                </div>
            </div>
            <div className="introduction" id="about" style={{'--title-color':THEME_COLORS.MCO_RED}}>
                <div className='introduction-text'>
                    <h2>About Us</h2>
                    <p>
                        The McGill Chinese Orchestra (MSCMS) is a community of musicians and enthusiasts dedicated to
                        showcasing the rich heritage of Chinese orchestral music. We bring together traditional
                        instruments, modern expressions, and diverse talents to create unforgettable performances.
                    </p>
                    <p>
                        Join us to celebrate cultural harmony and the joy of music!
                    </p>
                </div>
                {/* Photo Gallery */}
                <div className="introduction-photos">
                    <div className="photo-slider">
                        {photos.map((photo, index) => (
                            <img
                                key={index}
                                src={photo.url}
                                alt={photo.alt}
                                className={`slider-image ${
                                    index === currentIndex ? 'active' : ''
                                }`}
                            />
                        ))}
                    </div>
                    <button className="prev-btn" onClick={handlePrev}>←</button>
                    <button className="next-btn" onClick={handleNext}>→</button>
                </div>
            </div>
        </div>
    );
};

export default Home;
