import React from "react";
import './Events.css';

const Events = () => {
    return (
        <div className="content">
        <h1 className="font">Events</h1>
        </div>
    );
};

export default Events;
