import React from 'react';
import './ArrowCTA.css';

const ArrowCTA = ({ style }) => {
    return (
        <div className="arrow-cta-container" style={style}>
            <p className="cta-text">Check out our videos below!</p>
            <div className="arrow-wrapper">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
            </div>
        </div>
    );
};

export default ArrowCTA;
