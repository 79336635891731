import React, { useEffect, useState } from 'react';
import './Background.css';

function Background({route}) {
    const backgroundImages = {
        '/': "url('https://i.imgur.com/bADpOJD.jpg'), url('/assets/images/home-background.jpg')",
        '/events': "url('https://i.imgur.com/524ssWe.jpg'), url('/assets/images/events-background.jpg')",
    };
    const [scrollY, setScrollY] = useState(0);
    useEffect(() => {
        const throttle = (callback, limit) => {
          let lastCall = 0;
          return (...args) => {
            const now = Date.now();
            if (now - lastCall >= limit) {
              lastCall = now;
              callback(...args);
            }
          };
        };
        const handleScroll = throttle(() => {
          setScrollY(window.scrollY);
        }, 50);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);

    return (
      <>
        <div className="background-container">
          <div
            className="background-layer"
            style={{
                backgroundImage: backgroundImages[route] || backgroundImages['/'],
                transform: `scale(${1 + scrollY * 0.001})`,
            }}
          />
        </div>
      </>
    );
  }

  export default Background;
  