import React from 'react';
import './App.css';
import Navigation from './components/Navigation';
import Background from './components/Background';
import { Routes, Route, useLocation } from 'react-router-dom';
import Events from './pages/Events';
import Home from './pages/Home';


function App() {
  const location = useLocation();
  return (
    <div className="App">
        <Navigation />
        <Background route={location.pathname}/>
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/events" element={<Events />} />
          </Routes>
        </div>
    </div>
  );
}

export default App;
